/* Mixins */
.device-status-component .ant-collapse-content-box {
  padding: 0;
}
.device-status-component .ant-collapse-content-box .ant-list {
  border-radius: 0;
}
.device-status-component .ant-collapse-content-box .ant-list .ant-row {
  width: 100%;
}
.device-status-component .ant-collapse-content-box .ant-list .ant-row .ant-col {
  padding-left: 16px;
}